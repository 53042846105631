<template>
    <div class="weekSelector">


        <div class="btns">
            <button class="default small" @click="prevWeek"><i class="fas fa-chevron-left"></i></button>
            <div class="weekNum"><input type="text" :value="selectedWeek" @change="changeVal" @keypress.enter="changeVal"></div>
            <button class="default small" @click="nextWeek"><i class="fas fa-chevron-right"></i></button>
        </div>

    </div>
</template>

<script>
import moment from 'moment';

    export default {
        props: ['week', 'selectedYear', 'range'],
        data() {
            return {
                selectedWeek: this.week
            }
        },
        methods: {
            prevWeek() {
                if(this.selectedWeek <= 1) return this.selectedWeek = 52;
                this.selectedWeek--;
            },
            nextWeek() {
                if(this.selectedWeek >= 52) return this.selectedWeek = 1;
                this.selectedWeek++;
            },
            changeVal(e) {
                let val = Number(e.target.value);
                if(isNaN(val)) return e.target.value = this.selectedWeek;
                if(val > 52 || val < 1) return e.target.value = this.selectedWeek;
                this.selectedWeek = val;
            }
        },
        computed: {
            weekStart() {
                return moment().year(this.selectedYear).isoWeek(this.selectedWeek).startOf('isoWeek').format('MM/DD/YYYY');
            },
            weekEnd() {
                return moment(this.weekStart, 'MM/DD/YYYY').add(6, 'days').format('MM/DD/YYYY')
            },
            dateRange() {
                return {
                    from: moment(this.weekStart, 'MM/DD/YYYY').format('YYYY-MM-DD'),
                    to: moment(this.weekEnd, 'MM/DD/YYYY').format('YYYY-MM-DD')
                }
            }
        },
        watch: {
            selectedWeek() {
                this.$emit('update:week', this.selectedWeek);
            },
            dateRange() {
                this.$emit('update:range', this.dateRange);
            }
        },
        mounted() {
            this.$emit('update:range', this.dateRange);
        }
    }
</script>

<style lang="scss" scoped>

.btns {
    display: grid;
    grid-template-columns: 40px 50px 40px;
    align-items: center;
    align-content: center;
    height: 40px;
    border-radius: $borderRadius;
    background: $bgSecondary;
    button {
        width: 40px;
        height: 40px;
        background: transparent;
        color: $buttonColor;
        border-radius: 50%;
    }
}

.weekNum {
    text-align: center;
    height: 100%;
    input {
        width: 100%;
        height: 100%;
        background: transparent;
        text-align: center;
        border: 0;
    }
}

.weekSelector {
    display: flex;
    align-items: center;
    column-gap: 10px;
    position: relative;
}

.dateHolder {
    position: absolute;
    top: 0;
    left: 100%;
    transform: translateX(20px);
    display: flex;
    column-gap: 10px;
    display: none;
}

.date {
    height: 40px;
    border-radius: $borderRadius;
    background: $bg;
    display: grid;
    column-gap: 5px;
    grid-template-columns: auto auto;
    padding: 0 20px;
    align-items: center;
    align-content: center;
    width: 200px;
    font-size: 12px;
}

.weekSelector:hover {
    .dateHolder {
        display: block;
    }
}
</style>