<template>
    <div>
        
        <Table class="performanceUsersTable" :cols="['User', 'Salary']">
            <div class="row" :key="key" v-for="(item, key) of usersPerformance.list">
                <div>{{ item.user.name }}</div>
                <div>${{ priceConverter(item.report.total) }}</div>
            </div>
        </Table>
        
    </div>
</template>

<script>
    export default {
        props: ['performance'],
        computed: {
            usersPerformance() {
                return this.performance.users;
            }
        }
    }
</script>


<style lang="scss">
.performanceUsersTable {
    .row {
        grid-template-columns: minmax(0, 1fr) 150px !important;
    }
}
</style>

<style lang="scss" scoped>

.performanceUsersTable {
    .row {
        display: grid;
        grid-template-columns: minmax(0, 1fr) 150px !important;
        height: 50px;
        align-items: center;
        padding: 10px 20px;
        column-gap: 20px;

        &:nth-child(2n) {
            background: $bg;
        }
        &:last-child {
            border-radius: 0 0 $borderRadius $borderRadius;
        }
    }
}
</style>