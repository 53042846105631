<template>
    
    <div>
        <PageHeader title="Performance">
            <div class="action">
                <YearWeekSelector v-model:yearWeek="yearWeek"/>
            </div>
        </PageHeader>

        <div v-if="loading" class="wrapper loadingWrapper">
            <PageLoader/>
        </div>
        <div v-else>

            <div class="wrapper performanceWrapper" v-if="performance.carriers && performance.users">

                <div class="overStats">
                    <h2>Overall stats</h2>
                    <div class="_content">

                        <div class="statsGroup">
                            <div class="_title"><i class="fas fa-dollar-sign"></i><span>Gross income</span></div>
                            <div class="_value">${{ priceConverter(performance.carriers.gross) }}</div>
                        </div>

                        <div class="statsGroup">
                            <div class="_title"><i class="fas fa-dollar-sign"></i><span>Invoiced</span></div>
                            <div class="_value">${{ priceConverter(performance.carriers.income) }}</div>
                        </div>

                        <div class="statsGroup">
                            <div class="_title"><i class="fas fa-dollar-sign"></i><span>Salaries</span></div>
                            <div class="_value">${{ priceConverter(performance.users.total) }}</div>
                        </div>

                        <div class="statsGroup">
                            <div class="_title"><i class="fas fa-dollar-sign"></i><span>Net income</span></div>
                            <div class="_value">${{ priceConverter(performance.netIncome) }}</div>
                        </div>

                        

                    </div>
                </div>

                <div class="performanceDetails">

                    <h2>Details</h2>

                    <div class="sections">
                        <button class="default small round" @click="selectedSection = key" :class="{ 'reverse' : selectedSection != key }" :key="key" v-for="(item, key) of sections">{{ item.name }}</button>
                    </div>

                    <div class="contentGroup">
                        <component v-bind:is="sections[selectedSection].component" :performance="performance"></component>
                    </div>

                </div>

            </div>

        </div>

    </div>
</template>

<script>
import YearWeekSelector from '../../components/dispatch/YearWeekSelector.vue'
import moment from 'moment'

import CarriersDetails from '../../components/performance/CarriersDetails.vue'
import UsersDetails from '../../components/performance/UsersDetails.vue'

    export default {
        components: {
            YearWeekSelector,
            CarriersDetails,
            UsersDetails
        },
        data() {
            return {
                yearWeek: null,
                performance: {},
                selectedSection: 0,
                sections: [
                    { name: 'Carriers', component: 'CarriersDetails' },
                    { name: 'Users', component: 'UsersDetails' },
                ],
                loading: false,
                cancelTokenSource: null
            }
        },
        methods: {
            async getPerformance() {

                if(this.cancelTokenSource) this.cancelTokenSource.cancel();
                this.cancelTokenSource = this.$axios.CancelToken.source();

                this.loading = true;
                this.performance = {};

                await this.$axios.post(`/BusinessPerformance`, {
                    yearWeek: this.yearWeek,
                    date: this.selectedWeekDate
                }, 
                {
                    cancelToken: this.cancelTokenSource.token
                })
                .then(data => {
                    this.performance = data.data;
                    this.loading = false;
                })
                .catch(err => {  });

            }
        },
        computed: {
            selectedWeekDate(){
                let date = moment(this.yearWeek, "YYYYWW").format("MM/DD/YYYY");
                return date;
            }
        },
        watch: {
            yearWeek() {
                this.getPerformance();
            }
        }
    }
</script>

<style lang="scss" scoped>

.performanceWrapper {
    display: grid;
    row-gap: 20px;
}

.performanceDetails {
    display: grid;
    row-gap: 20px;
}

.overStats {
    display: grid;
    row-gap: 20px;
    ._content {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        column-gap: 20px;
    }
}

.loadingWrapper {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sections {
    display: flex;
    align-items: center;
    button {
        margin-right: 10px;
    }
}
</style>