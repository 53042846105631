<template>
    <div>
        
        <Table class="performanceCarriersTable" :cols="['Carrier', 'Gross', 'Rate', 'Income']">
            <div class="row" :key="key" v-for="(item, key) of carriersPerformance.list">
                <div>{{ item.carrier }}</div>
                <div>${{ priceConverter(item.gross.toFixed(2)) }}</div>
                <div>{{ item.rate }}</div>
                <div>${{ priceConverter(item.income.toFixed(2)) }}</div>
            </div>
        </Table>
        
    </div>
</template>

<script>
    export default {
        props: ['performance'],
        computed: {
            carriersPerformance() {
                return this.performance.carriers;
            }
        }
    }
</script>


<style lang="scss">
.performanceCarriersTable {
    .row {
        grid-template-columns: minmax(0, 1fr) 150px 150px 150px !important;
    }
}
</style>

<style lang="scss" scoped>

.performanceCarriersTable {
    .row {
        display: grid;
        grid-template-columns: minmax(0, 1fr) 150px 150px 150px !important;
        height: 50px;
        align-items: center;
        padding: 10px 20px;
        column-gap: 20px;

        &:nth-child(2n) {
            background: $bg;
        }
        &:last-child {
            border-radius: 0 0 $borderRadius $borderRadius;
        }
    }
}
</style>