<template>
    <div class="yearWeekSelector">
        <SimpleDropDown class="year" v-tooltip="'Display Year'" :options="[2022, 2021, 2020]" v-model="selectedYear"/>
        <WeekSelector class="week" v-tooltip="'Display Week'" v-model:week="selectedWeek" :selectedYear="selectedYear"/>
    </div>
</template>

<script>
import WeekSelector from '../../components/WeekSelector.vue'
import moment from 'moment'
    export default {
        components: {
            WeekSelector
        },
        props: ['year', 'week'],
        data() {
            return {
                selectedYear: this.year || moment().year(),
                selectedWeek: this.week || moment().isoWeek(),
            }
        },
        computed: {
            yearWeek() {
                return this.selectedYear + "W" + this.selectedWeek;
            }
        },
        mounted() {
             this.$emit('update:year', this.selectedYear);
             this.$emit('update:week', this.selectedWeek);
              this.$emit('update:yearWeek', this.yearWeek);
        },
        watch: {
            selectedYear(newValue, oldValue) {
                this.$emit('update:year', this.selectedYear);
            },
            selectedWeek(newValue, oldValue) {
                this.$emit('update:week', this.selectedWeek);
            },
            yearWeek(newValue, oldValue) {
                this.$emit('update:yearWeek', this.yearWeek);
            }
        },
    }
</script>

<style lang="scss" scoped>
.yearWeekSelector {
    display: flex;
    align-items: center;
    column-gap: 20px;
    .year, .week {
        box-shadow: $boxShadow;
    }
}
</style>